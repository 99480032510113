import React from "react"
import SolutionsLayout from "../../components/SolutionsLayout"

export default function ServicesIndex() {
  return (
    <SolutionsLayout>
      <h2 className="heading-2 left">Feeling overwhelmed by the cloud?</h2>
      <p className="paragraph left">
        <strong className="bold-text">
          Let us help you.
          <br />
          <br />
          We have years of experience helping companies of any size fully
          embrace the cloud and its methodologies.
        </strong>
        <br />
        <br />
        Whether you're looking at integrating DevOps principles in your culture
        or need assistance building a new infrastructure, we are ready to
        collaborate with your teams.
        <br />
        <br />
        Our agile, no-frills approach makes sure you understand what is
        happening at any given time.
        <br />
        Feel free to reach out and we'll get you the help you deserve.
      </p>
    </SolutionsLayout>
  )
}
